import React, { createContext, useState, useEffect } from 'react';
import { getTranslations } from '../i18n';

export const I18nContext = createContext();

export const I18nProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    setTranslations(getTranslations(language));
  }, [language]);

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <I18nContext.Provider value={{ translations, switchLanguage, language }}>
      {children}
    </I18nContext.Provider>
  );
};
