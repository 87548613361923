import React from 'react';
import './Notifications.css'; // 确保此 CSS 文件存在并正确导入

const Notifications = () => {
    const notifications = [
        { id: 1, title: '必读', content: '服务于想了解最先进技术的小白，我们帮你去除复杂安装配置流程，', timestamp: '为小白服务' },
        { id: 2, title: '小白搭建AI工具助手', content: '微信：NXY500231', timestamp: '在线早上9点-晚上10' },
        { id: 3, title: '小白搭建AI工具助手', content: 'QQ：1696506230', timestamp: '在线早上9点-晚上10' },
        { id: 1, title: '新功能-可搭建访问正版chatGPT', content: '手动搭建，不满意包退，适合小白，提供教程以及售后', timestamp: '体验先进工具AI工具' },
  
        // 根据需要添加更多通知
    ];

    return (
        <div className="notifications-container">
            <h2>系统通知</h2>
            <ul className="notification-list">
                {notifications.map(notification => (
                    <li key={notification.id} className="notification-item">
                        <div className="notification-header">
                            <span className="notification-title">{notification.title}</span>
                            <span className="notification-timestamp">{notification.timestamp}</span>
                        </div>
                        <p className="notification-content">{notification.content}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Notifications; 