import React, { useState } from 'react';
import './SearchIcon.css';

const SearchIcon = ({ onSearchClick }) => {
    const [showSearch, setShowSearch] = useState(false);

    const toggleSearch = () => {
        setShowSearch(!showSearch);
        onSearchClick(!showSearch); // 通知 Header 搜索状态的变化
    };

    return (
        <div className="search-container">
            <div className={`search-box ${showSearch ? 'show' : ''}`}>
                <input type="text" className="search-input" placeholder="搜索..." />
                <div className="search-icon" onClick={toggleSearch}>
                    🔍
                </div>
            </div>
            {!showSearch && (
                <div className="search-icon" onClick={toggleSearch}>
                    🔍
                </div>
            )}
        </div>
    );
};

export default SearchIcon;

