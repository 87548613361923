// import React from 'react';
// import { useLocation } from 'react-router-dom';

// const MainContentWrapper = ({ children }) => {
//     const location = useLocation();
//     const isRootPath = location.pathname === '/user-agreement';

//     return (
//         <div className={isRootPath ? '' : 'main-content1'}>
//             {children}
//         </div>
//     );
// };

// export default MainContentWrapper;
import React from 'react';
import { useLocation } from 'react-router-dom';

const MainContentWrapper = ({ children }) => {
    const location = useLocation();
    
    const getClassNameForPath = (path) => {
        switch (path) {
            case '/':
                return 'main-content';
            case '/user-agreement':
                return 'user-agreement-content';
            case '/privacy-policy':
                return 'main-content1';
            case '/messages':
                return 'messages';
             case '/discovery':
                return 'discovery';
            case '/contactus':
                return 'contactus';
            default:
                return '';
        }
    };

    const className = getClassNameForPath(location.pathname);

    return (
        <div className={className}>
            {children}
        </div>
    );
};

export default MainContentWrapper;
