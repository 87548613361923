import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { ThemeContext } from '../../src/contexts/ThemeContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SharedComponent.css'; // Import the CSS file

const SharedComponent = () => {
  const { category } = useParams();
  const [showScrollTop, setShowScrollTop] = useState(false);
  const { theme } = useContext(ThemeContext);

  const data = {
    "home": {
      title: "一站式AI写作",
      description: "代表工具：ChatGPT、Jasper、Copy.ai。功能：支持内容创作、博客写作、营销文案、社交媒体内容、产品描述等。",
      images: [
        "https://img2.baidu.com/it/u=1028011339,1319212411&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313",
        "https://img1.baidu.com/it/u=728576857,3157099301&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313",
        "https://img0.baidu.com/it/u=3643028167,149007168&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500"
      ],
      features: [
        {
          name: "是不是想体验新技术，但自己弄太难？",
  
          painPoints: "我们帮你搭建，解决信息过载问题，省时省力。"
        },
        {
          name: "你想提高你的办事效率吗？",
      
          painPoints: "我们帮你搭建先进工具，提高你的工作和效率，减少时间浪费。"
        },
        {
          name: "是不是找不到途径获取到自己想要的内容？",
  
          painPoints: "没事，我们能帮你提供一些资源"
        },
        {
            name: "担心技术更新太快，跟不上节奏？",
            innovation: "我们提供持续更新的技术支持，确保您始终走在前沿。",
            painPoints: "我们为你推送先进技术文章，不再为落后而担忧。"
        }, {
            name: "想要个性化的解决方案，但不知道如何实现？",
            innovation: "我们提供定制化服务，满足您的特定需求。",
            painPoints: "获得量身定制的解决方案，确保最佳效果。"
        },
   

      ]
    },
    "ai-writing": {
        title: "一站式AI写作",
        description: "代表工具：ChatGPT、Jasper、Copy.ai。功能：支持内容创作、博客写作、营销文案、社交媒体内容、产品描述等。",
        images: [
          "https://img2.baidu.com/it/u=1028011339,1319212411&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313",
          "https://img1.baidu.com/it/u=728576857,3157099301&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313",
          "https://img0.baidu.com/it/u=3643028167,149007168&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500"
        ],
        features: [
          {
            name: "国外正版ChatGPT体验o1【博士级】",
            innovation: "使用AI算法自动优化流程，提供实时建议。",
            painPoints: "体验最先进的技术"
          },
          {
            name: "增加你的知识",
            innovation: "提供个性化的内容推荐。",
            painPoints: "他提供个性化的学习建议和资源，帮助你提升技能和知识。"
          },
          {
            name: "节省你的时间",
            innovation: "自动化工具，提升用户体验的独特性。",
            painPoints: "协助你进行内容创作，如写作、编辑和校对，提供创意灵感,帮助你在博客、社交媒体和专业写作中提高创作效率。"
          },
          {
            name: "陪伴你",
            innovation: "自动化工具，提升用户体验的独特性。",
            painPoints: "陪你聊天，帮助你应对压力和情绪问题。"
          },
          {
            name: "及时翻译",
            innovation: "自动化工具，提升用户体验的独特性。",
            painPoints: "帮助你进行语言翻译和学习。"
          }
        ]
      },
    "ai-programming": {
      title: "一站式AI编程",
      description: "代表工具：GitHub Copilot、Tabnine、CodeWhisperer。功能：自动生成代码片段、自动补全代码、错误排查、代码重构和文档生成。",
      images: [
        "https://img2.baidu.com/it/u=1028011339,1319212411&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313",
        "https://img1.baidu.com/it/u=728576857,3157099301&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313",
        "https://img0.baidu.com/it/u=3643028167,149007168&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500"
      ],
      features: [
        {
            name: "全球内容访问",
            painPoints: "你可以可以观看到更多样化的国际影视剧、纪录片和综艺节目，丰富娱乐生活。"
        },    {
            name: "获取最先进的技术与资讯",
            painPoints: "了解最新的科技发展和流行趋势，保持与全球同步。"
        }, {
            name: "海外教育资源",

            painPoints: "海外视频平台提供在线课程和教育资源，支持你持续学习",

        },
        
      ]
    },
    // Add more categories as needed
  };

  const content = data[category] || { title: "未知类别", description: "没有找到相关信息。", images: [], features: [] };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={`shared-component ${theme}`}>
      <Carousel>
        {content.images.map((src, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 custom-carousel-img"
              src={src}
              alt={`Slide ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="feature-list">
        {content.features.map((feature, index) => (
          <div key={index} className="feature-item">
            <div className="feature-header">
              <h3>{feature.name}</h3>
              <button className="contact-button">
                &gt;
              </button>
            </div>
          
            <p>{feature.painPoints}</p>
          </div>
        ))}
      </div>
      {showScrollTop && (
        <div className="scroll-to-top" onClick={scrollToTop}>
          上
        </div>
      )}
    </div>
  );
};

export default SharedComponent; 