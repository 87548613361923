import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from './SearchIcon';
import { ThemeContext } from '../../contexts/ThemeContext';
import './Header.css';
const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showTitle, setShowTitle] = useState("一站式最前沿AI工具搭建");
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const { theme, toggleTheme } = useContext(ThemeContext);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleSearchClick = (isSearchOpen) => {
        if (window.innerWidth <= 768) {
            setShowTitle(!isSearchOpen ? "一站式最前沿AI工具搭建" : "");
        } else {
            setShowTitle("一站式最前沿AI工具搭建");
        }
    };

    const handleLinkClick = (title) => {
        setShowTitle(title);
        toggleMenu(); // Close the menu after clicking a link
    };
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > lastScrollY) {
                // Scrolling down
                setIsVisible(false);
            } else {
                // Scrolling up
                setIsVisible(true);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);
    return (
        <div className={`header ${isVisible ? 'visible' : 'hidden'} ${theme}`}>
            <h1 style={{ color: theme === 'light' ? 'black' : 'white', textAlign: 'center', fontSize: "17px" }}>{showTitle}</h1>
            <div className="menu-icon" style={{ color: theme === 'light' ? 'black' : 'white', zIndex: 9999 }} onClick={toggleMenu}>
                &#9776;
            </div>
            <nav className={`nav-links ${menuOpen ? 'open' : ''} ${theme}`}>
            <Link to="/home" onClick={() => handleLinkClick("服务说明")}>服务说明【必读】</Link>
                <Link to="/ai-writing" onClick={() => handleLinkClick("搭建正版chatgpt")}>给你电脑/手机搭建正版chatgpt</Link>
                <Link to="/ai-programming" onClick={() => handleLinkClick("搭建访问海外视频平台")}>给你电脑/手机搭建访问海外视频平台</Link>
                {/* <Link to="/ai-image-generation" onClick={() => handleLinkClick("一站式AI图片生成工具搭建")}>一站式AI图片生成工具搭建</Link>
                <Link to="/ai-video-generation" onClick={() => handleLinkClick("一站式AI视频生成工具搭建")}>一站式AI视频生成工具搭建</Link>
                <Link to="/ai-data-analysis" onClick={() => handleLinkClick("一站式AI数据分析工具搭建")}>一站式AI数据分析工具搭建</Link>
                <Link to="/ai-customer-service" onClick={() => handleLinkClick("一站式AI客户服务工具搭建")}>一站式AI客户服务工具搭建</Link>
                <Link to="/ai-voice" onClick={() => handleLinkClick("一站式AI语音生成与识别工具搭建")}>一站式AI语音生成与识别工具搭建</Link>
                <Link to="/ai-ecommerce" onClick={() => handleLinkClick("一站式AI电子商务解决方案工具搭建")}>一站式AI电子商务解决方案工具搭建</Link>
                <Link to="/ai-medical" onClick={() => handleLinkClick("一站式AI医学诊断与分析工具搭建")}>一站式AI医学诊断与分析工具搭建</Link>
                <Link to="/ai-education" onClick={() => handleLinkClick("一站式AI教育工具搭建")}>一站式AI教育工具搭建</Link>
                <Link to="/ai-marketing" onClick={() => handleLinkClick("一站式AI营销自动化工具搭建")}>一站式AI营销自动化工具搭建</Link>
                <Link to="/ai-finance" onClick={() => handleLinkClick("一站式AI金融分析工具搭建")}>一站式AI金融分析工具搭建</Link> */}
            </nav>
            <div className="icons">
                <SearchIcon onSearchClick={handleSearchClick} />
                <Link to="/notifications" className="notification-icon">
                    🔔
                </Link>
                <div className="theme-toggle" onClick={toggleTheme}>
                    {theme === 'light' ? '🌞' : '🌜'}
                </div>
            </div>
        </div>
    );
};

export default Header;
