import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {
    const [showDialog, setShowDialog] = useState(false);

    const handleContactClick = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert(`已复制: ${text}`);
        }, () => {
            alert('复制失败，请手动复制。');
        });
    };

    return (
        <footer className="footer">
            <div className="desktop-footer">
                <p>&copy; 2024 知多点 All rights reserved.</p>
            </div>
            <div className="mobile-footer">
            
                <button 
                    className="download-button" 
                    onClick={handleContactClick}
                >
                    联系我们
                </button>
            </div>

            {showDialog && (
                <div className="contact-dialog">
                    <div className="dialog-content">
                        <h3>联系信息</h3>
                        <p>微信号: <span onClick={() => handleCopy('your-wechat-id')}>NXY500231</span></p>
                        <p>QQ号: <span onClick={() => handleCopy('your-qq-id')}>1696506230</span></p>
                        <button onClick={handleCloseDialog}>关闭</button>
                    </div>
                </div>
            )}
        </footer>
    );
};

export default Footer;
