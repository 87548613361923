import en from './es.json';
import zh from './zh.json';

const languages = {
  en,
  zh,
};

export const getTranslations = (lang) => {
  return languages[lang] || languages.en;
};
