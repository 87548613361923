import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nProvider } from './contexts/I18nContext';
import { ThemeProvider } from './contexts/ThemeContext';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import MainContentWrapper from './MainContentWrapper';
import SharedComponent from '@/pages/SharedComponent';
import Notifications from '@/pages/Notifications';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {
  componentDidMount() {
    const renderTime = performance.now();
    console.log(`首屏渲染时间: ${renderTime.toFixed(2)}毫秒`);
  }

  render() {
    return (
      <Router>
        <div>
          <I18nProvider>
            <ThemeProvider>
              <Header />
              <MainContentWrapper>
                <Routes>
                  <Route path="/:category" element={<SharedComponent />} />
                  <Route path="/notifications" element={<Notifications />} />
                </Routes>
              </MainContentWrapper>
              <Footer />
            </ThemeProvider>
          </I18nProvider>
        </div>
      </Router>
    );
  }
}

export default App;
